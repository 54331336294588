<template>
  <ListPage
    :gridData="filteredData"
    :gridConfig="gridConfig"
    :filters="filters"
    v-if="allCivilCases"
  />
</template>

<script>
import { mapGetters } from "vuex";
import ListPage from "@/components/penal-retail-helpers/ListPage";

import civilCasesFormConfig from "@/helpers/civilCasesFormConfig";

let collectionName = "civilCases";

const findAttributeExtra = (rows, attributeName) => {
  return rows
    .find((r) => r.attributes.some((a) => a.attribute === attributeName))
    .attributes.find((a) => a.attribute === attributeName).extra;
};

export default {
  components: {
    ListPage,
  },
  computed: {
    ...mapGetters(["allCivilCases", "allCustomers", "allLawyers"]),
    isLawyer() {
      return ["lawyer"].includes(this.$store.state.AppActiveUser.role);
    },
    isCustomerUser() {
      return ["customer", "supercustomer"].includes(
        this.$store.state.AppActiveUser.role
      );
    },
    civilCustomers() {
      return this.allCustomers.filter((c) =>
        c.casesTypes.map((c) => c.id).includes("civil")
      );
    },
    filteredData() {
      let filteredData = this.allCivilCases;
      ["customer", "lawyer", "procedure", "status"].forEach((a) => {
        if (this.appliedFilters[a]) {
          filteredData = filteredData.filter(
            (x) => x[a] && x[a].id === this.appliedFilters[a].id
          );
        }
      });
      return filteredData;
    },
    filters() {
      let rows = civilCasesFormConfig(
        this,
        this.$store.state.AppActiveUser.role,
        false
      );
      let filters = [];
      filters.push({ size: "1/6", mobileSize: "1/2", search: true });
      if (!this.isCustomerUser) {
        filters.push({
          size: "1/6",
          mobileSize: "1/2",
          select: true,
          placeholder: `${collectionName}.placeholders.customer`,
          label: "alias",
          clearable: true,
          resetOnOptionsChange: true,
          onChange: (e) => {
            this.updateFilters("customer", e);
          },
          options: () => this.civilCustomers,
        });
      }
      filters.push({
        size: "1/6",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.lawyer`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("lawyer", e);
        },
        options: findAttributeExtra(rows, "lawyer").optionsFunction,
      });
      filters.push({
        size: "1/6",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.procedure`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("procedure", e);
        },
        options: () => findAttributeExtra(rows, "procedure").options,
      });
      filters.push({
        size: "1/6",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.status`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("status", e);
        },
        options: () => findAttributeExtra(rows, "status").options,
      });

      filters.push({
        size: this.isCustomerUser ? "1/3" : "1/6",
        mobileSize: "1/2",
        button: true,
        i18n: `${collectionName}.buttons.new`,
        extraClasses: "text-right",
        to: { name: "civil-cases-new" },
      });

      return filters;
    },
  },
  data() {
    let columns = [];

    const formatDatetime = (data) => {
      return (
        this.$moment(data.value.toDate()).format("DD-MM-YYYY HH:mm") || null
      );
    };

    const valueGetterAlias = (key) => {
      return (p) => p.data[key].alias;
    };

    columns.push({
      headerName: this.$t(`${collectionName}.columns.cid`),
      field: "cid",
      filter: false,
      width: 50,
    });
    columns.push({
      headerName: this.$t(`${collectionName}.columns.date`),
      field: "date",
      filter: false,
      width: 75,
      valueFormatter: formatDatetime,
    });

    ["customer", "lawyer", "procedure"].forEach((a) => {
      columns.push({
        headerName: this.$t(`${collectionName}.columns.${a}`),
        field: a,
        filter: false,
        width: 75,
        valueGetter: valueGetterAlias(a),
        filterValueGetter: valueGetterAlias(a),
      });
    });

    columns.push({
      headerName: "",
      field: "status",
      filter: false,
      width: 50,
      filterValueGetter: valueGetterAlias("status"),
      cellRendererFramework: "CellRendererCivilStatus",
    });

    return {
      appliedFilters: {
        customer: null,
        lawyer: null,
        procedure: null,
        status: null,
      },
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  methods: {
    updateFilters(key, value) {
      if (this.appliedFilters[key] !== value) {
        this.appliedFilters[key] = value;
      }
    },
    onRowClicked(params) {
      this.$router
        .push({ name: "civil-cases-edit", params: { id: params.data.id } })
        .catch(() => {});
    },
  },
};
</script>
